.mobile-menu {
	position: fixed;
	top: 0;
	z-index: 9999;
	width: 100%;
	height: 100%;
	min-width: 320px;
	overflow: auto;
	background: $white;
	transform: translateX(100%);
	transition: transform 0.3s ease-in-out;
	will-change: transform;
	font-family: "Work Sans";

	@media (max-width: 320px) {
		position: sticky;
	}

	&--active {
		transition: transform 0.4s ease-in-out;
		transform: translateX(0);
	}

	&__header {
		padding: 8px 0;
		display: flex;
		align-items: center;
	}

	&__cross {
		padding: 6px;
		height: 32px;
		width: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid $n-75;
		border-radius: 4px;
		cursor: pointer;

		&:hover {
			background-color: $n-95;
		}

		svg {
			display: block;
		}
	}

	&__close {
		width: 24px;
		height: 24px;
		display: block;
		background: url("../../public/assets/media/closeMenu.svg") no-repeat center;
		background-size: contain;
		cursor: pointer;
	}

	&__logo {
		width: auto;
		height: 16px;
		display: block;

		img {
			width: 100%;
			height: 100%;
		}
	}

	&__link {
		margin: 0;
		padding: 14px 24px;
		display: flex;
		justify-content: flex-start;
		@include font-base-14($n-17, 700);
		cursor: pointer;

		&--book-now {
			@include font-base-14($p-37, 700);
			background-image: linear-gradient(to bottom, $p-90 0%, $p-98 100%);
		}

		&.active {
			color: $p-48;
		}

		&--docs {
			position: relative;
			padding-right: 16px;

			&::before {
				position: absolute;
				left: 60px;
				bottom: 17px;
				height: 12px;
				width: 12px;
				background: url("../../public/assets/media/docs-icon.svg") no-repeat;
				content: "";
			}
		}
	}

	&--bottom {
		display: flex;
		flex-direction: column;
	}

	&__actions {
		padding: 12px 24px;
		display: flex;
		justify-content: center;
		gap: 4px;

		a {
			flex-grow: 1;
		}
	}

	&__dropdown {
		padding: 0 24px;
		display: flex;
		flex-direction: column;
		cursor: pointer;
		@include font-base-14($n-17, 700);

		&--top {
			padding: 14px 0;
			display: flex;
			justify-content: space-between;
		}

		&-list {
			display: flex;
			flex-direction: column;
			max-height: 0;
			visibility: hidden;
			transition:
				max-height 0.3s ease,
				visibility 0.3s;
			overflow: hidden;
		}

		svg {
			transition: transform 0.3s ease;

			path {
				transition: fill 0.3s ease;
			}
		}

		&-item {
			padding: 8px 0;
			cursor: pointer;

			a {
				display: block;
			}

			& + .mobile-menu__dropdown-item {
				border-top: 1px solid $n-90;
			}

			&--inner {
				position: relative;
				padding: 6px 8px 6px 36px;
				height: 100%;
				width: 100%;
				display: flex;
				flex-direction: column;
				border-radius: 4px;

				img {
					position: absolute;
					top: 6px;
					left: 8px;
					height: 20px;
					width: 20px;
				}

				&-title {
					margin-bottom: 4px;
					@include font-base-14($n-17, 700);
				}

				&-content {
					@include font-base-12($n-35);
				}
			}
		}

		&--active {
			.mobile-menu__dropdown-list {
				visibility: visible;
				max-height: 9999px;
				transition: max-height 0.3s 0.2s ease;
			}

			svg {
				transform: rotate(180deg);
				transition: transform 0.3s ease;

				path {
					fill: $n-17;
				}
			}
		}
	}
}

.content--column {
	display: flex;
	flex-direction: column;
}

.content--space-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
