.modal {
	@include full-size(fixed, 0, 0, 0, 0);
	width: 100%;
	height: 100%;
	background-color: rgba(106, 109, 138, 0.3);
	overflow-y: scroll;
	z-index: 9;

	&__box {
		position: absolute;
		left: 50%;
		margin: 120px auto 60px;
		padding: 0;
		width: 824px;
		border-radius: 12px;
		box-shadow: 0 4px 12px 0 $n-35;
		display: block;
		transform: translate(-50%, 0);
		background: $white;

		@include tablet-portrait {
			margin-top: 40px;
			width: 720px;
		}

		@media only screen and (max-height: 500px) and (max-width: 900px) {
			top: 0%;
			margin: 0;
			width: 100%;
			height: 100%;
			overflow: scroll;
		}

		@include phone {
			padding-top: 48px;
			top: 0%;
			margin: 0;
			width: 100%;
			height: 100%;
			overflow: scroll;
		}
	}

	.content {
		@include phone {
			padding: 0;
		}
	}
}
