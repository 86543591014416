.navigation-bar {
	position: fixed;
	z-index: 9999;
	top: 0;
	width: 100%;
	min-width: 320px;
	-webkit-transition: transform 0.3s cubic-bezier(0, 0.65, 0.58, 1);
	transition: transform 0.3s cubic-bezier(0, 0.65, 0.58, 1);
	-webkit-transform: translateY(0);
	transform: translateY(0);
	will-change: transform;
	background-color: $white;
	font-family: "Work Sans";

	@media (max-width: 320px) {
		margin-bottom: -48px;
		position: sticky;
	}

	@include phone {
		transform: translateX(0);

		&--menu-active {
			transition: transform 0.4s ease-in-out;
			transform: translateX(-100%);
		}
	}

	&--transformed-out {
		-webkit-transition: transform 0.5s ease-in-out;
		transition: transform 0.5s ease-in-out;
		-webkit-transform: translateY(-150%);
		transform: translateY(-150%);
	}

	&--dark {
		border-bottom: 1px solid $n-90;
		background-color: $white;
		background-image: radial-gradient(circle at 0 0, $n-95, $n-90);

		@include tablet-portrait {
			.navigation-bar__mobile-menu-button {
				height: 32px;
				@include button-base($white, $n-95, $n-75 1px solid);
			}
		}

		@include phone {
			.navigation-bar__mobile-menu-button {
				height: 32px;
				@include button-base($white, $n-95, $n-75 1px solid);
			}
		}
	}

	&--transparent {
		background-color: transparent;
		box-shadow: none;

		.navigation-bar__logo {
			filter: brightness(20);
		}

		.navigation-bar__link {
			color: $white;
		}

		.navigation-bar__actions .button {
			color: $n-17;
			background-color: $white;
		}

		.navigation-bar__dropdown {
			color: $white;

			&:hover {
				svg path {
					fill: $white;
				}
			}
		}

		.navigation-bar__actions-link {
			color: $white;
		}

		.navigation-bar__link--book-now {
			color: $n-17;
		}
	}

	&--dark.navigation-bar--no-gradient {
		background-image: none;
	}

	&--default-bg {
		background-color: $white;

		.navigation-bar__gradient-fader {
			background: rgba(255, 255, 255, 1);
		}

		.navigation-bar__menu {
			@include button-base($n-95, $n-90);
		}
	}

	&__logo {
		width: 117px;
		height: 16px;

		@include tablet {
			width: 117px;
			height: 20px;
		}

		@include tablet-portrait {
			width: 117px;
			height: 16px;
		}

		@include phone {
			width: 117px;
			height: 16px;
		}

		img {
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	&__nav {
		margin-left: 12px;
		display: flex;
		align-items: center;

		.divider {
			margin-right: 4px;
			height: 32px;
		}

		@include tablet-portrait {
			display: none;
		}

		@include phone {
			display: none;
		}
	}

	&__actions {
		display: flex;
		gap: 12px;

		&-link {
			display: flex;
			justify-content: center;
			align-items: center;
			@include font-base-14($n-17, 700);
			cursor: pointer;

			&--docs {
				position: relative;
				padding-right: 16px;

				&::before {
					position: absolute;
					right: 0;
					bottom: 9px;
					height: 12px;
					width: 12px;
					background: url("../../public/assets/media/docs-icon.svg") no-repeat;
					content: "";
				}
			}

			&:hover,
			&--active {
				text-decoration: underline;
			}
		}

		.divider {
			height: 32px;
		}

		@include tablet-portrait {
			display: none;
		}

		@include phone {
			display: none;
		}
	}

	&__link {
		position: relative;
		padding: 14px 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		@include font-base-14($n-17, 700);
		cursor: pointer;

		&::before {
			position: absolute;
			left: 0;
			bottom: 0;
			height: 4px;
			width: 100%;
			background-color: $p-48;
			content: "";
			opacity: 0;
		}

		&:first-child {
			margin-left: 0;
		}

		&:hover,
		&--active {
			&::before {
				transition: opacity 0.3s ease;
				opacity: 1;
			}
		}

		&--book-now {
			@include font-base-14($p-37, 700);
			background-image: linear-gradient(to bottom, $p-90 0%, $p-98 100%);
			text-transform: capitalize;
		}
	}

	&__dropdown {
		position: relative;
		padding: 14px 24px 14px 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		@include font-base-14($n-17, 700);
		cursor: pointer;

		&::before {
			position: absolute;
			left: 0;
			bottom: 0;
			height: 4px;
			width: 100%;
			background-color: $p-48;
			content: "";
			opacity: 0;
		}

		&:hover {
			&::before {
				transition: opacity 0.3s ease;
				opacity: 1;
			}

			svg path {
				fill: $n-17;
			}
		}

		svg {
			position: absolute;
			bottom: 17px;
			right: 8px;
			height: 12px;
			width: 12px;
			transform-origin: center;
			transition: transform 0.3s ease;
		}

		&--active {
			.navigation-bar__dropdown-list {
				visibility: visible;
				opacity: 1;
			}

			svg {
				transform: rotate(180deg);
			}
		}

		&-list {
			position: absolute;
			top: 56px;
			left: 0;
			display: flex;
			flex-direction: column;
			gap: 1px;
			background-color: $n-90;
			border-radius: 8px;
			box-shadow:
				0 0 1px 0 rgba(45, 45, 57, 0.5),
				0 8px 12px -4px rgba(45, 45, 57, 0.3);
			overflow: hidden;
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s ease;

			&--columns {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 1px;
				background-color: $n-90;

				.navigation-bar__dropdown-item {
					width: 238px;
				}
			}
		}

		&-item {
			padding: 8px;
			width: 268px;
			background-color: $white;
			cursor: default;

			a {
				display: block;
				height: 100%;
			}

			&:hover,
			a:focus {
				.navigation-bar__dropdown-item--inner {
					background-image: linear-gradient(
						to bottom,
						rgba(255, 255, 255, 0) 0%,
						$p-95 100%
					);

					&-title {
						@include font-base-14($p-37, 700);
						text-decoration: underline;
					}
				}
			}

			&--inner {
				position: relative;
				padding: 6px 8px 6px 36px;
				height: 100%;
				width: 100%;
				display: flex;
				flex-direction: column;
				border-radius: 4px;

				img {
					position: absolute;
					top: 6px;
					left: 8px;
					height: 20px;
					width: 20px;
				}

				&-title {
					@include font-base-14($n-17, 700);
				}

				&-content {
					@include font-base-12($n-35);
				}
			}
		}
	}

	&__mobile-menu {
		display: none;

		&-button {
			height: 32px;

			@include tablet-portrait {
				padding: 0 12px;
				display: flex;
				align-items: center;
				@include font-base-14($n-17, 700);
				@include button-base($white, $n-95, $n-75 1px solid);
			}

			@include phone {
				padding: 0 12px;
				display: flex;
				align-items: center;
				@include font-base-14($n-17, 700);
				@include button-base($white, $n-95, $n-75 1px solid);
			}
		}

		@include tablet-portrait {
			padding: 8px 0;
			display: flex;

			.button--register {
				margin-right: 8px;
			}
		}

		@include phone {
			padding: 8px;
			display: flex;
			.button--register {
				margin-right: 8px;
				white-space: nowrap;
			}
		}
	}

	&__gradient-fader {
		transition:
			transform 0.3s ease-in-out,
			background-color 0.3s ease-in-out;
	}

	&--blog-page {
		box-shadow: none;
		background-color: rgba($color: $white, $alpha: 0.5);
		backdrop-filter: blur(8px);
	}
}

.content__navigation-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&--left {
		display: flex;
		align-items: center;
	}
}
