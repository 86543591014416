.footer {
	width: 100%;
	background-color: $n-95;
	font-family: "Work Sans";

	&--top {
		padding-top: 32px;
		padding-bottom: 32px;
		background-color: $white;

		&-content {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include phone-portrait {
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}

	&__media {
		display: flex;
		align-items: center;
		@include font-base-14($n-17);

		@include phone {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__logo {
		width: 174px;
		height: 24px;
		display: block;

		@include phone-portrait {
			margin-bottom: 12px;
		}

		img {
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	&__info {
		padding: 24px 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 24px;

		@include tablet-portrait {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
		}

		@include phone {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&__icon {
		width: 100%;
		height: 100%;
	}

	&__link {
		margin-top: 4px;
		@include font-base-14($n-17);
		cursor: pointer;

		@include phone {
			margin-top: 4px;
		}

		&:hover {
			text-decoration: underline;
			color: $n-35;
			transition: all 0.3s ease;
		}

		&--socials {
			margin-top: 0;
			margin-left: 16px;
			width: 28px;
			height: 28px;
			display: flex;

			&:first-of-type {
				margin-left: 0;
			}
		}
	}

	&--center {
		background-color: $y-98;
	}

	&__col {
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		@include phone {
			flex-grow: 0;

			&:first-of-type {
				margin-top: 0;
			}
		}
	}

	&__title {
		margin-bottom: 12px;
		@include font-base-16($n-17, 700);

		& + .footer__link {
			margin-top: 0;
		}
	}

	&__separator {
		border-bottom: 1px solid $n-80;
	}

	&__bottom {
		&-info {
			padding: 20px 0;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			@include tablet-portrait {
				flex-direction: column;
				row-gap: 8px;
			}

			@include phone {
				flex-direction: column;
				row-gap: 8px;
			}
		}

		&-links {
			display: flex;
			column-gap: 16px;

			@include phone {
				flex-wrap: wrap;
				justify-content: center;
				row-gap: 8px;
			}
		}

		&-link {
			border: 1px solid transparent;
			@include font-base-12($n-17, 700);
			cursor: pointer;

			&:hover {
				text-decoration: underline;
				color: $n-35;
				transition: all 0.3s ease;
			}

			&--socials {
				margin-top: 0;
				margin-left: 16px;
				width: 24px;
				height: 24px;
				display: flex;

				&:first-of-type {
					margin-left: 0;
				}
			}
		}
	}

	&__external {
		margin-left: 12px;
		display: flex;
		align-items: center;

		@include phone {
			margin-top: 4px;
			margin-left: 0;
		}
	}

	&__copyright {
		@include font-base-12($n-35);
		text-align: center;

		@include tablet {
			text-align: center;
		}

		@include tablet-portrait {
			text-align: center;
		}

		@include phone {
			text-align: center;
		}
	}

	&__iso-badge {
		margin-top: 24px;
	}
}
