@import "./mixins";

@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIUwaEQbjB_mQ.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4uaVIUwaEQbjB_mQ.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBi8Jpo3ZKyHaQQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
		U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXBi8Jpo3ZKyHaQQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
		U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQBi8Jpo3ZKyHaQQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
		U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "proxima-soft";
	src:
		url("https://use.typekit.net/af/a727c9/00000000000000007735af8e/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/a727c9/00000000000000007735af8e/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
			format("woff"),
		url("https://use.typekit.net/af/a727c9/00000000000000007735af8e/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
			format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "proxima-soft";
	src:
		url("https://use.typekit.net/af/eab1a9/00000000000000007735af92/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/eab1a9/00000000000000007735af92/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3")
			format("woff"),
		url("https://use.typekit.net/af/eab1a9/00000000000000007735af92/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3")
			format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "proxima-soft";
	src:
		url("https://use.typekit.net/af/81cbd6/00000000000000007735afa9/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/81cbd6/00000000000000007735afa9/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
			format("woff"),
		url("https://use.typekit.net/af/81cbd6/00000000000000007735afa9/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
			format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	clear: both;

	&:focus {
		outline: transparent;
	}
}

html,
body,
#root,
.app {
	position: relative;
	margin: 0;
	padding: 0;
	width: 100%;
	min-width: 310px;
	height: 100%;
	min-height: 475px;
	display: block;
	font-family: "proxima-soft", sans-serif;
	font-display: swap;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.app-layout,
.app-layout__content-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-x: clip;
}

.app-layout--mobile-menu-active {
	display: block;
	height: unset;

	.app-layout__content-wrapper {
		display: none;
	}
}

html {
	-webkit-text-size-adjust: 100%;
	scroll-padding-top: 48px;
}

a {
	text-decoration: none;
}

button,
input,
textarea {
	font-family: "proxima-soft", sans-serif;
	font-display: swap;
}

// remove yellow background for autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px white inset !important;
}

h1 {
	@include font-base-36($n-17, 700);
}

h2 {
	@include font-base-28($n-17, 700);
}

h3 {
	@include font-base-18($n-17, 700);
}

p {
	@include font-base-16($n-35);
	margin-top: 20px;

	&:first-of-type {
		margin-top: 0;
	}
}

// Custom keyboard focus when tabbing
html[data-tabbing="true"] *:focus,
html[data-tabbing="true"] .form-field .input:-webkit-autofill:focus,
html[data-tabbing="true"] .StripeElement--focus {
	box-shadow:
		0 0 0 1px white,
		0 0 0 3px $b-44 !important;
	transition: all 0.3s ease !important;
}

html[data-tabbing="true"] .form-field input:focus,
html[data-tabbing="true"] .timer input:focus,
html[data-tabbing="true"] .form-field .input:-webkit-autofill:focus,
html[data-tabbing="true"] .StripeElement--focus {
	border: 1px solid $n-75 !important;
}

.no-scroll {
	overflow: hidden !important;
}

.no-overscroll-behavior {
	overscroll-behavior-x: none;
	-ms-scroll-chaining: none;
	overflow-x: hidden;
}

.no-transition {
	* {
		transition: none !important;
	}
}

.app-root {
	position: relative;
	width: 100%;
	height: 100%;

	.payments-tooltip {
		padding: 16px;
		border-radius: 10px;
		background: $n-17;
		opacity: 1 !important;
		@include font-base-16($white);
		letter-spacing: -0.4px;
	}
}

.content {
	margin: 0 auto;
	padding: 0 120px;
	max-width: 1440px;
	width: 100%;

	&--flex {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	@include tablet {
		padding: 0 32px;
	}

	@include phone-landscape {
		padding: 0 46px;
	}

	@include tablet-portrait {
		padding: 0 32px;
	}

	@include phone-portrait {
		padding: 0 32px;
	}

	&--small-phone {
		@media (max-width: 374px) {
			padding: 4px;
		}
	}
}

.container {
	position: relative;
	height: 100%;
	min-height: inherit;
	max-width: 1366px;
	padding-right: 95px;
	padding-left: 95px;
	margin: 0 auto;

	@include tablet {
		max-width: 100%;
		padding-right: 95px;
		padding-left: 95px;
	}

	@include tablet-portrait {
		max-width: 100%;
		padding-right: 44px;
		padding-left: 44px;
	}

	@include phone {
		max-width: 100%;
		min-width: 320px;
		padding-right: 20px;
		padding-left: 20px;
	}
}

.dotted {
	border-bottom: 2px dotted $n-35;
}

@include phone {
	.animated-in {
		transition: transform 0.3s ease-in-out;
		transform: translateX(0);
		will-change: transform;
	}
}

#__next {
	position: relative;
	padding: 0;
	margin: 0;
	min-width: 320px;
	width: 100%;
	height: 100%;

	.landing-content {
		@include phone {
			transition: transform 0.3s ease-in-out;
			&.pushed-out {
				transition: transform 0.4s ease-in-out;
				transform: translateX(-100%);
			}
		}
	}

	.landing-wrapper {
		position: relative;
		padding-top: 48px;
	}
}

.web-rtc,
.load-test,
.performance-test {
	@include phone {
		padding-bottom: 40px;
	}

	.carousel-wrapper {
		height: 452px;
		margin-bottom: 30px;
	}
}

@import "../components/Components";
@import "./toasts";
