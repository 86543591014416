.modal {
	@include full-size(fixed, 0, 0, 0, 0);
	width: 100%;
	height: 100%;
	background-color: rgba(106, 109, 138, 0.3);
	overflow-y: scroll;
	z-index: 9999999;

	&.disabled {
		display: none;
	}

	&__divider {
		height: 1px;
		border: 1px solid $n-90;
	}

	&__container {
		padding: 16px;

		&--pull-right {
			display: flex;
			justify-content: flex-end;
		}

		&--no-padding {
			padding: 0;
		}

		&--no-bottom-padding {
			padding-bottom: 0;
		}

		&--large-padding {
			padding: 32px 16px;
		}
	}
}

@import "./contactForm/ContactForm";
@import "./contactUsModal/ContactUsModal";
@import "./cookies/CookiesBanner";
@import "./footer/Footer";
@import "./header/Header";
@import "./icon/Icon";
@import "./mobileMenu/MobileMenu";
@import "./successModal/SuccessModal";
