@import "../../styles/mixins";

.page-not-found {
	position: relative;
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&__logo-link {
		position: absolute;
		top: 40px;
		left: 40px;
	}

	&__external-link {
		padding: 12px 16px;
		margin-right: 8px;
		height: 40px;
		@include font-base-16($white, 700);
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		text-decoration: unset;
		transition: all 0.3s ease;
		background: $p-48;

		&:hover {
			background: $p-37;
		}
	}

	&__logo {
		width: 154px;
		height: 21px;
	}

	&__content-block {
		position: relative;
		margin: 0 auto;
		height: 80%;
		display: flex;

		@include phone-landscape {
			padding: 80px 0;
			height: 100%;
			flex-direction: column;
		}

		@include phone-portrait {
			padding: 80px 24px;
			height: 100%;
			flex-direction: column;
		}
	}

	&__right-col {
		padding-top: 165px;
		flex: 1;

		@include phone-landscape {
			padding-top: 24px;
		}

		@include phone-portrait {
			padding-top: 24px;
		}

		@include phone-small {
			padding-top: 0;
		}

		img {
			margin: 0 auto;
			max-height: 100%;
			max-width: 100%;
			height: auto;

			@include phone-landscape {
				margin-left: auto;
				margin-right: auto;
				height: auto;
				width: 100%;
				display: block;
			}

			@include phone-portrait {
				margin-left: auto;
				margin-right: auto;
				height: auto;
				width: 100%;
				display: block;
			}
		}
	}

	&__left-col {
		margin: 25px;
		max-width: 360px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include phone-small {
			margin: 0 0 25px 0;
		}
	}

	&__sub-message {
		padding-bottom: 24px;
		@include font-base-14($n-17);

		@include phone-landscape {
			text-align: center;
		}

		@include phone-portrait {
			text-align: center;
		}

		@include phone-small {
			padding-bottom: 12px;
		}
	}

	&__redirect {
		display: flex;
		justify-content: left;
		align-items: center;
		text-align: center;

		@include phone-landscape {
			justify-content: center;
		}

		@include phone-portrait {
			justify-content: center;
		}
	}

	&__message {
		padding-bottom: 24px;
		@include font-base-32($n-17, 700);

		@include phone-landscape {
			text-align: center;
		}

		@include phone-portrait {
			text-align: center;
		}

		@include phone-small {
			padding-bottom: 12px;
			@include font-base-24($n-17, 700);
		}
	}

	&__status-code {
		padding-bottom: 8px;
		@include font-base-64($n-17, 700);

		@include phone-landscape {
			text-align: center;
		}

		@include phone-portrait {
			text-align: center;
		}
	}

	.button--not-found-primary {
		margin-right: 8px;
	}

	.button--not-found-secondary {
		color: $p-48;
		border: 1px solid $p-48;
		background: $white;

		&:hover {
			border-color: $p-37;
			color: $p-37;
			background: $white;
		}
	}
}
