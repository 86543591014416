.c-wrapper {
	position: fixed;
	top: 0;
	height: 100%;
	z-index: 999999;
	overflow: scroll;
	scrollbar-width: none;
	font-family: "Work Sans";

	&::-webkit-scrollbar {
		display: none;
	}

	@media (max-width: 265px) {
		width: 100%;
	}

	@media (max-height: 316px) {
		box-shadow: none;
	}

	.button {
		font-family: "Work Sans";
	}
}

.c-banner {
	position: fixed;
	bottom: 32px;
	left: 40px;
	padding: 16px;
	width: 260px;
	border: none;
	border-radius: 8px;
	background-color: $white;
	box-shadow: 0 0 1px 0 rgba(45, 45, 57, 0.5),
		0 8px 12px -4px rgba(45, 45, 57, 0.3);

	@include tablet-portrait {
		left: 24px;
	}

	@include phone {
		left: 16px;
		padding: 16px;
		width: 252px;
	}

	@media (max-height: 316px) {
		position: unset;
		top: 0;
		margin: 8px;
		height: auto;
		max-height: unset;
	}

	@media screen and (max-width: 265px) and (max-height: 316px) {
		margin-left: 0;
	}

	&__accept-button {
		margin-bottom: 8px;
	}

	&__note {
		margin-bottom: 12px;
		@include font-base-12($n-17);

		span a {
			@include font-base-12($p-48, 700);
			text-decoration: underline;
		}

		@include phone {
			margin-bottom: 16px;
		}
	}

	&__title {
		margin-bottom: 8px;
		@include font-base-20($n-17, 700);
	}

	.button--full-width {
		width: 100%;
	}
}

.c-modal {
	position: fixed;
	bottom: 32px;
	left: 40px;
	padding: 16px;
	width: 260px;
	max-height: 100%;
	min-height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: none;
	border-radius: 8px;
	background-color: $white;
	box-shadow: 0 0 1px 0 rgba(45, 45, 57, 0.5),
		0 8px 12px -4px rgba(45, 45, 57, 0.5);

	@include tablet-portrait {
		left: 24px;
	}

	@media (max-height: 772px) {
		max-height: calc(100% - 32px);
		transition: max-height 0.3s ease;
	}

	@include phone {
		left: 16px;
		padding: 16px;
	}

	@media (max-width: 265px) {
		position: unset;
		left: 0;
	}

	@media (max-height: 316px) {
		position: unset;
		left: 0;
		top: 0;
		margin-left: 16px;
		height: auto;
		max-height: unset;
		box-shadow: none;
		border: 1px solid $n-90;
	}

	@media screen and (max-width: 265px) and (max-height: 316px) {
		margin-left: 0;
	}

	&__block {
		padding: 12px 0 8px;
		border-top: 1px solid $n-90;

		&:first-of-type {
			padding: 0 0 8px;
			border: none;
		}

		&-top {
			margin-bottom: 8px;
			display: flex;
			justify-content: space-between;
		}

		&-label {
			@include font-base-14($n-17, 700);

			span {
				@include font-base-12($n-35);
			}
		}

		&-note {
			@include font-base-12($n-17);
		}
	}

	&__button {
		width: 100%;
	}

	&__switchers {
		position: relative;

		&-wrapper {
			margin-bottom: 8px;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		&-bg {
			display: none;

			@media (max-height: 740px) {
				position: absolute;
				bottom: 55px;
				left: 16px;
				display: block;
				width: 228px;
				height: 17px;
				content: "";
				background-image: linear-gradient(
					to bottom,
					rgba(38, 38, 48, 0) 0%,
					rgba(38, 38, 48, 0.2) 100%
				);
			}

			@media (max-height: 316px) {
				display: none;
			}
		}
	}

	&__title {
		margin-bottom: 8px;
		@include font-base-20($n-17, 700);
	}
}
