$white: #ffffff;
$b-44: #1c8cc4;
$g-17: #0b4c32;
$g-36: #17a06a;
$g-55: #36e29d;
$g-95: #e9fcf4;
$n-17: #262630;
$n-17-2: rgba(38, 38, 48, 0.2);
$n-17-3: rgba(68, 67, 86, 0.3);
$n-17-8: rgba(68, 67, 86, 0.8);
$n-35: #4f4f64;
$n-60: #8d8da5;
$n-75: #b8b8c7;
$n-80: #c6c6d2;
$n-90: #e3e3e8;
$n-95: #f1f1f4;
$p-17: #251046;
$p-37: #502499;
$p-48: #682ec7;
$p-60: #8959d9;
$p-90: #e2d6f5;
$p-95: #f0ebfa;
$p-98: #f9f7fd;
$r-39: #ae192e;
$r-55: #e2364f;
$t-14: #064a51;
$t-36: #0d9eaa;
$t-55: #29deee;
$t-80: #a1eff7;
$t-95: #e7fbfd;
$y-14: #443404;
$y-36: #ae8709;
$y-55: #f3c425;
$y-95: #fef8e7;
$y-90: #fcf2cf;
$y-98: #f9f9fb;

@mixin large-laptop {
	@media (min-width: 1800px) {
		@content;
	}
}

@mixin laptop {
	@media (min-width: 1200px) and (max-width: 1799px) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: 1024px) and (max-width: 1199px) {
		@content;
	}
}

@mixin tablet-portrait {
	@media (min-width: 768px) and (max-width: 1023px) {
		@content;
	}
}

@mixin phone-landscape {
	@media only screen and (min-width: 480px) and (max-width: 900px) {
		@content;
	}
}

@mixin phone-landscape-iphone-x {
	@media only screen and (min-width: 480px) and (max-width: 900px) and (max-height: 375px) {
		@content;
	}
}

@mixin phone-landscape-iphone6 {
	@media (max-width: 667px) {
		@content;
	}
}

@mixin phone {
	@media (max-width: 767px) {
		@content;
	}
}

@mixin phone-portrait {
	@media (max-width: 550px) {
		@content;
	}
}

@mixin phone-small {
	@media (max-width: 391px) {
		@content;
	}
}

@mixin phone-iphone-5 {
	@media (max-width: 350px) {
		@content;
	}
}

@mixin button-base(
	$bg: $wisteria,
	$hoverBG: $twilight,
	$border: none,
	$radius: 4px
) {
	border-radius: $radius;
	border: $border;
	background: $bg;
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		background: $hoverBG;
	}
}

@mixin full-size($pos: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0) {
	position: $pos;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin pseudo($content: "", $display: block, $pos: absolute) {
	content: $content;
	position: $pos;
	display: $display;
}

@mixin thumb-base() {
	width: 32px;
	height: 32px;
	border: 1px solid $white;
	background: url("../public/assets/media/slider.svg") no-repeat;
	background-position: center;
	background-size: 80%;
	background-color: $white;
	border-radius: 50%;
	box-shadow: 0 3px 8px 0 rgba(68, 67, 86, 0.2);
	cursor: pointer;
	-webkit-appearance: none;
}

@mixin thumb() {
	&::-webkit-slider-thumb {
		@include thumb-base();
	}

	&::-moz-range-thumb {
		@include thumb-base();
	}

	&::-ms-thumb {
		@include thumb-base();
	}
}

@mixin center() {
	position: relative;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

@mixin font-base($color, $weight) {
	color: $color;

	@if $weight == 700 {
		font-weight: 700;
	} @else if $weight == 600 {
		font-weight: 600;
	} @else if $weight == 400 {
		font-weight: 400;
	} @else if $weight == 300 {
		font-weight: 300;
	} @else {
		font-weight: 400;
	}
}

@mixin font-bases($size, $colour, $weight, $lh) {
	font-size: $size;
	font-weight: $weight;
	line-height: $lh;
	color: $colour;
}

@mixin font-base-10($color, $bold: 400) {
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.4px;

	@include font-base($color, $bold);
}

@mixin font-base-12($color, $bold: 400) {
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.2px;

	@include font-base($color, $bold);
}

@mixin font-base-13($color, $bold: 400) {
	font-size: 13px;
	line-height: 16px;

	@include font-base($color, $bold);
}

@mixin font-base-14($color, $bold: 400) {
	font-size: 14px;
	line-height: 20px;

	@include font-base($color, $bold);
}

@mixin font-base-16($color, $bold: 400) {
	font-size: 16px;
	line-height: 24px;

	@include font-base($color, $bold);
}

@mixin font-base-18($color, $bold: 400) {
	font-size: 18px;
	line-height: 24px;

	@include font-base($color, $bold);
}

@mixin font-base-20($color, $bold: 400) {
	font-size: 20px;
	line-height: 28px;

	@include font-base($color, $bold);
}

@mixin font-base-22($color, $bold: 400) {
	font-size: 22px;
	line-height: 28px;

	@include font-base($color, $bold);
}

@mixin font-base-23($color, $bold: 400) {
	font-size: 23px;
	line-height: 32px;

	@include font-base($color, $bold);
}

@mixin font-base-24($color, $bold: 400) {
	font-size: 24px;
	line-height: 32px;

	@include font-base($color, $bold);
}

@mixin font-base-25($color, $bold: 400) {
	font-size: 25px;
	line-height: 36px;

	@include font-base($color, $bold);
}

@mixin font-base-26($color, $bold: 400) {
	font-size: 26px;
	line-height: 36px;

	@include font-base($color, $bold);
}

@mixin font-base-28($color, $bold: 400) {
	font-size: 28px;
	line-height: 36px;

	@include font-base($color, $bold);
}

@mixin font-base-30($color, $bold: 400) {
	font-size: 30px;
	line-height: 40px;

	@include font-base($color, $bold);
}

@mixin font-base-31($color, $bold: 400) {
	font-size: 31px;
	line-height: 40px;

	@include font-base($color, $bold);
}

@mixin font-base-32($color, $bold: 400) {
	font-size: 32px;
	line-height: 40px;

	@include font-base($color, $bold);
}

@mixin font-base-35($color, $bold: 400) {
	font-size: 35px;
	line-height: 44px;

	@include font-base($color, $bold);
}

@mixin font-base-36($color, $bold: 400) {
	font-size: 36px;
	line-height: 44px;

	@include font-base($color, $bold);
}

@mixin font-base-39($color, $bold: 400) {
	font-size: 39px;
	line-height: 48px;

	@include font-base($color, $bold);
}

@mixin font-base-49($color, $bold: 400) {
	font-size: 49px;
	line-height: 64px;

	@include font-base($color, $bold);
}

@mixin font-base-64($color, $bold: 400) {
	font-size: 64px;
	line-height: 76px;

	@include font-base($color, $bold);
}
