.success-modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(106, 109, 138, 0.3);
	overflow-y: scroll;
	z-index: 9999;
	font-family: "Work Sans";

	&__box {
		position: absolute;
		left: 50%;
		padding: 100px 0 80px 0;
		margin: 120px auto 60px;
		width: 424px;
		height: 352px;
		border-radius: 6px;
		display: flex !important;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		box-shadow: 0 4px 12px 0 #4f4f64;
		transform: translate(-50%, 0);
		background: $white;

		@include phone {
			width: 90%;
		}
	}

	&__close {
		position: absolute;
		top: 24px;
		right: 24px;
		width: 16px;
		height: 16px;
		cursor: pointer;

		&:hover path {
			fill: $n-35;
		}
	}

	&__icon {
		width: 192px;
		height: auto;
		margin-bottom: 24px;

		img {
			width: 100%;
			height: auto;
		}
	}

	&__heading {
		margin-bottom: 16px;
		width: unset;
		@include font-base-39($n-17, 700);
		text-align: center;
		letter-spacing: -0.7px;
	}

	&__subtitle {
		@include font-base-16($n-35);
		text-align: center;
		letter-spacing: -0.4px;
	}

	.icon {
		width: 100%;
		height: 70px;
	}
}
