.contact-form {
	width: 100%;

	.content {
		height: 100%;

		@include phone {
			padding: 0;
		}
	}

	&__character-count--active {
		color: $r-39;
	}

	&__title {
		margin-bottom: 6px;
		@include font-base-39($n-17, 700);
		text-align: center;

		@include phone {
			@include font-base-31($n-17, 700);
		}
	}

	&__subtitle {
		padding-bottom: 5px;
		margin: 0 auto;
		@include font-base-16($n-17);
		text-align: center;
		max-width: 375px;

		&--pricing {
			max-width: 500px;
		}
	}

	&__user-details {
		margin: 0 auto;
		width: 100%;
		display: flex;
		justify-content: space-between;

		@include phone-portrait {
			flex-direction: column;
		}

		.form-field {
			width: 48%;

			@include phone-portrait {
				margin-bottom: 16px;
				width: 100%;
			}
		}
	}

	form {
		margin: 0 auto;
		height: 100%;
	}

	textarea {
		resize: vertical;
		letter-spacing: -0.4px;

		@include tablet {
			resize: none;
		}

		@include tablet-portrait {
			resize: none;
		}

		@include phone {
			resize: none;
		}
	}

	&__cancel {
		margin-right: 8px;
	}

	&__button-wrapper {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	&--page {
		padding: 56px;
		background: $n-95;

		@include phone-landscape {
			padding: 40px 46px;
			flex-direction: column;
		}

		@include phone-portrait {
			padding: 40px 32px !important;
			flex-direction: column;
		}

		form {
			max-width: 776px;
		}

		.contact-form__send-msg {
			margin-top: 16px;

			@include phone {
				width: 100%;
			}
		}

		.contact-form__title,
		.contact-form__subtitle {
			margin: 0;
			@include font-base-39($n-17, 700);
			text-align: center;
			letter-spacing: -0.7px;

			@include tablet {
				margin: 0 auto;
				@include font-base-31($n-17, 700);
			}

			@include tablet-portrait {
				margin: 0 auto;
				max-width: 534px;
				@include font-base-31($n-17, 700);
			}

			@include phone {
				@include font-base-31($n-17, 700);
			}
		}

		.contact-form__subtitle {
			margin: 0 auto 32px;
			padding-bottom: 0;
		}

		.contact-form__user-details {
			margin: 0 auto;
			width: 100%;
			display: flex;
			justify-content: space-between;

			@include tablet-portrait {
				flex-direction: column;
			}

			@include phone {
				flex-direction: column;
			}

			.form-field {
				width: 48%;

				@include tablet-portrait {
					margin-bottom: 24px;
					width: 100%;
				}

				@include phone {
					margin-bottom: 24px;
					width: 100%;
				}
			}
		}
	}
}
